// transcribeAudio.ts
import axios from 'axios';

const OPENAI_API_BASE_URL = 'https://api.openai.com/v1';

export const transcribeAudio = async (
  audioFile: File,
  model: string = 'whisper-1'
): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('model', model);
    console.log(formData);

    const response = await axios.post(
      `${OPENAI_API_BASE_URL}/audio/transcriptions`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data.text.trim();
  } catch (error) {
    console.error('Error transcribing audio:', error);
    throw error;
  }
};
