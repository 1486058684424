//Footer.tsx
import React from 'react';
import styles from './Footer.module.css';

const Footer: React.FC = () => {
  return (
    <footer className={`bg-secondary text-white py-1 ${styles.footerFixedBottom}`}>
      <div className="container">
        <p>GPT Chatbot App © 2023. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
