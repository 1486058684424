// Header.tsx
import React from 'react';
import styles from './Header.module.css';
import { Navbar, Container, Button } from 'react-bootstrap';
import { AiOutlineMenu } from 'react-icons/ai';
import { useChat } from '../../contexts/ChatContext';

const Header: React.FC = () => {
  const { toggleSidebar } = useChat();

  return (
    <Navbar expand="md" sticky="top" className={`shadow p-0 ${styles.header}`}>
      <Container fluid>
        <Button variant="link" className={`text-light ${styles.menuButton}`} onClick={toggleSidebar}>
          <AiOutlineMenu size={24} />
        </Button>
        <Navbar.Brand className={`ml-3 ${styles.customHeaderTitle}`}>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
