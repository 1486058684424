// App.tsx
import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import ChatArea from './components/Chat/ChatArea/ChatArea';
import Footer from './components/Footer/Footer';
import { ChatProvider } from './contexts/ChatContext';

const App: React.FC = () => {
  return (
    <div className="App d-flex flex-column h-100">
        <ChatProvider>
          <Header />
          <main className="d-flex flex-grow-1">
            <Sidebar />
            <div className="container-fluid flex-grow-1 d-flex flex-column">
              <div className="row flex-grow-1">
                <div className="col-md-12 d-flex flex-column">
                  <ChatArea />
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </ChatProvider>
    </div>
  );
};

export default App;
