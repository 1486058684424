//Sidebar.tsx
import React, { useState } from 'react';
import styles from './Sidebar.module.css';
import { Nav, Button, Offcanvas } from 'react-bootstrap';
import { useChat } from '../../contexts/ChatContext';
import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { Topic } from '../../types';

const Sidebar: React.FC = () => {
  const { topics, activeTopic, changeActiveTopic, createTopic, removeTopic, updateTopicTitle, sidebarShow, toggleSidebar } = useChat();

  const [editingTopicId, setEditingTopicId] = useState<number | null>(null);

  const handleDeleteClick = (topic: Topic) => {
    if (window.confirm("Are you sure you want to delete this topic?")) {
      removeTopic(topic.id);
    }
  };

  const handleCreateClick = () => {
    createTopic();
  };

  const handleEditClick = (topic: Topic) => {
    setEditingTopicId(topic.id);
  };

  const handleTitleChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    updateTopicTitle(id, event.target.value);
  };

  const handleTitleBlur = () => {
    setEditingTopicId(null);
  };

  const isEditableTopic = (topic: Topic) => {
    return topic.mode !== 'roleplay';
  };

  const TopicList = ({ topics, mode }: { topics: Topic[], mode: string }) => (
    <Nav className={`flex-column`}>
      {topics
        .filter((topic) => topic.mode === mode)
        .map((topic) => (
          <Nav.Item key={topic.id} className={styles.topicItem}>
            <Nav.Link
              href="#"
              className={`${styles.navLink} ${topic.id === activeTopic.id ? styles.navLinkActive : ''}`}
              onClick={() => changeActiveTopic(topic)}
            >
              {topic.id === editingTopicId ? (
                <input
                  type="text"
                  value={topic.title}
                  onChange={(event) => handleTitleChange(topic.id, event)}
                  onBlur={handleTitleBlur}
                />
              ) : (
                <span>{topic.title}</span>
              )}
              {isEditableTopic(topic) && (
                <>
                  <AiOutlineEdit className={`${styles.icon} ${styles.iconHover}`} onClick={() => handleEditClick(topic)} />
                  <AiOutlineDelete className={`${styles.icon} ${styles.iconHover}`} onClick={() => handleDeleteClick(topic)} />
                </>
              )}
            </Nav.Link>
          </Nav.Item>
        ))}
    </Nav>
  );

  return (
    <>
      <Offcanvas show={sidebarShow} onHide={toggleSidebar} className={styles.sidebar} id="sidebarMenu" placement="start">
        <Offcanvas.Header closeButton={false}>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body id="sidebarMenu">
          <div className="position-sticky pt-3">
            <div className={styles.createButton} onClick={handleCreateClick}>
              <AiOutlinePlus className={styles.createButtonIcon} />
              新規トピックを作成
            </div>

            <h4 className={`${styles.sectionTitle} ${styles.sectionTitleFreeTalk}`}>フリートークトピック</h4>
            <TopicList topics={topics} mode="freetalk" />
            <div className={styles.divider}></div>
            <h4 className={`${styles.sectionTitle} ${styles.sectionTitleRolePlay}`}>ロールプレイトピック</h4>
            <TopicList topics={topics} mode="roleplay" />

          </div>
        </Offcanvas.Body>
      </Offcanvas >
    </>
  );
};

export default Sidebar;

