// polly.tsx
import AWS from "aws-sdk";

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

AWS.config.update({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
  region: "ap-northeast-1",
});

const polly = new AWS.Polly();

export async function synthesizeSpeech(text: string, language: 'ja' | 'en'): Promise<ArrayBuffer> {
  
  const voiceId = language === 'ja' ? 'Tomoko' : 'Salli'; // or any other English voice

  const params = {
    Engine: "neural",
    OutputFormat: "mp3",
    Text: text,
    TextType: "text",
    VoiceId: voiceId,
  };

  return new Promise((resolve, reject) => {
    polly.synthesizeSpeech(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        const audioStream = data.AudioStream as ArrayBuffer;
        resolve(audioStream);
      }
    });
  });
}
