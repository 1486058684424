// ChatContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Topic, Message } from '../types';
import { fetchGPTResponse } from '../utils/fetchGPTResponse';
import { getTopicsData } from './topicsData';
import { synthesizeSpeech } from '../utils/polly';
import { defaultInitialMessage } from './topicsData'

// チャットコンテキストの型定義
type ChatContextType = {
    topics: Topic[];
    activeTopic: Topic;
    addMessage: (topicId: number, message: Message) => void;
    changeActiveTopic: (topic: Topic) => void;
    createTopic: () => void;
    removeTopic: (id: number) => void;
    updateTopicTitle: (id: number, title: string) => void;
    sendChatMessage: (message: string) => Promise<void>;
    modifyMessage: (topicId: number, updatedMessage: Message) => void;
    sidebarShow: boolean; // 追加
    toggleSidebar: () => void; // 追加
};

const ChatContext = createContext<ChatContextType>({} as ChatContextType);

// チャットコンテキストを使用するためのカスタムフック
export const useChat = () => {
    return useContext(ChatContext);
};

type ChatProviderProps = {
    children: React.ReactNode;
};

// チャットの状態を管理するプロバイダーコンポーネント
export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
    const [topics, setTopics] = useState<Topic[]>([]);
    const [activeTopic, changeActiveTopic] = useState<Topic | null>(null);
    const [nextTopicId, setNextTopicId] = useState<number>(10);

    const [sidebarShow, setSidebarShow] = useState(false);
    const toggleSidebar = () => setSidebarShow(prevShow => !prevShow);

    // 初期化処理
    useEffect(() => {
        (async () => {
            const topicsData = await getTopicsData();
            const { freetalkTopics, roleplayTopics } = topicsData;
            setTopics([...freetalkTopics, ...roleplayTopics]);
            changeActiveTopic(freetalkTopics[0]);
            setNextTopicId(prevId => prevId + 1);
        })();
    }, []);

    // メッセージをトピックに追加する関数
    const addMessage = (topicId: number, message: Message) => {
        setTopics((prevTopics) => {
            const newTopics = [...prevTopics];
            const targetIndex = newTopics.findIndex((topic) => topic.id === topicId);

            if (targetIndex !== -1) {
                const targetTopic = newTopics[targetIndex];
                const updatedTopic = {
                    ...targetTopic,
                    messages: [...(targetTopic.messages || []), message],
                };
                newTopics[targetIndex] = updatedTopic;
                changeActiveTopic(updatedTopic);
            }

            return newTopics;
        });
    };

    // トピック内のメッセージを更新する関数
    const modifyMessage = (topicId: number, updatedMessage: Message) => {
        setTopics((prevTopics) => {
            const updatedTopics = prevTopics.map((topic) => {
                if (topic.id === topicId) {
                    return {
                        ...topic,
                        messages: topic.messages.map((message) => {
                            if (message.id === updatedMessage.id) {
                                return updatedMessage;
                            } else {
                                return message;
                            }
                        }),
                    };
                } else {
                    return topic;
                }
            });

            return updatedTopics;
        });
    };

    // 新しいトピックを作成する関数
    const createTopic = () => {
        setTopics((prevTopics) => {
            const newTopic: Topic = {
                id: nextTopicId,
                title: `トピック ${nextTopicId}`,
                mode: 'freetalk',
                messages: [defaultInitialMessage],
            };

            setNextTopicId(nextTopicId + 1);

            return [...prevTopics, newTopic];
        });
    };

    // トピックを削除する関数
    const removeTopic = (id: number) => {
        setTopics((prevTopics) => {
            const updatedTopics = prevTopics.filter((topic) => topic.id !== id);

            if (!updatedTopics.some((topic) => topic.mode === 'freetalk')) {
                createTopic();
                return updatedTopics;
            }

            return updatedTopics;
        });
    };

    // トピックのタイトルを編集する関数
    const updateTopicTitle = (id: number, title: string) => {
        setTopics((prevTopics) => {
            const newTopics = [...prevTopics];
            const targetIndex = newTopics.findIndex((topic) => topic.id === id);

            if (targetIndex !== -1) {
                const targetTopic = newTopics[targetIndex];
                const updatedTopic = {
                    ...targetTopic,
                    title,
                };
                newTopics[targetIndex] = updatedTopic;
                changeActiveTopic(updatedTopic);
            }

            return newTopics;
        });
    };

    // メッセージを送信する関数
    const sendChatMessage = async (message: string) => {
        if (activeTopic === null) {
            return;
        }

        const userMessage: Message = {
            id: (activeTopic.messages.length || 0) * 2,
            text: message,
            user: 'user',
        };
        addMessage(activeTopic.id, userMessage);

        try {
            await new Promise((resolve) => setTimeout(resolve, 10));

            const response = await fetchGPTResponse(
                message,
                activeTopic.messages || [],
                activeTopic
            );

            const botMessage: Message = {
                id: ((activeTopic.messages.length || 0) * 2) + 1,
                text: response,
                user: 'assistant',
                audio: undefined,
            };
            addMessage(activeTopic.id, botMessage);

            (async () => {
                try {
                    console.log('activeTopic');
                    console.log(activeTopic);
                    const language = activeTopic.freetalkInfo?.language || activeTopic.roleplayInfo?.language || 'ja';
                    const audio = await synthesizeSpeech(response, language);
                    botMessage.audio = audio;
                    modifyMessage(activeTopic.id, botMessage);
                    const audioElement = new Audio(URL.createObjectURL(new Blob([audio], { type: "audio/mpeg" })));
                    audioElement.play();
                } catch (error) {
                    console.error('Error getting or playing audio:', error);
                }
            })();

        } catch (error) {
            console.error('Error sending message:', error);
            const errorMessage: Message = {
                id: ((activeTopic.messages.length || 0) * 2) + 1,
                text: '申し訳ありません。問題が発生しました。もう一度お試しください。',
                user: 'assistant',
            };
            addMessage(activeTopic.id, errorMessage);
        }
    };

    const value: ChatContextType = {
        topics,
        activeTopic: activeTopic || topics[0],
        addMessage,
        changeActiveTopic,
        createTopic,
        removeTopic,
        updateTopicTitle,
        sendChatMessage,
        modifyMessage,
        sidebarShow,
        toggleSidebar,
    };

    return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};