// AudioRecorder.tsx
import React, { useState, useRef, useEffect } from "react";

interface AudioRecorderProps {
  onRecordingComplete: (file: File) => void;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ onRecordingComplete }) => {
  const [recording, setRecording] = useState<boolean>(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const audioUrlRef = useRef<string | null>(null);

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.addEventListener("dataavailable", (event) => {
        const audioBlob = event.data;
        if (audioBlob.size > 0) {
          const audioFile = new File([audioBlob], "recording.webm", {
            type: mediaRecorder.mimeType,
          });
          onRecordingComplete(audioFile);
          audioUrlRef.current = URL.createObjectURL(audioBlob);
          console.log("録音ファイルが作成されました");
        }
      });

      mediaRecorder.addEventListener("stop", () => {
        // Stop all tracks to remove the recording icon
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      });
    }
  }, [mediaRecorder, onRecordingComplete]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const recorder = new MediaRecorder(stream, { mimeType: "audio/webm;codecs=opus" });
    recorder.start();
    setRecording(true);
    setMediaRecorder(recorder);

    console.log("録音が開始されました");
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      console.log("録音が停止されました");
    }
  };

  return (
    <div className="d-flex">
      {!recording ? (
        <button onClick={startRecording} className="btn btn-primary me-2" type="button">
          <i className="fas fa-microphone"></i>
        </button>
      ) : (
        <button onClick={stopRecording} className="btn btn-danger me-2" type="button">
          <i className="fas fa-stop"></i>
        </button>
      )}
    </div>
  );
  

};

export default AudioRecorder;