// transformMessages.ts
import { Message, Topic } from '../types';

export const transformMessages = (
  messagesHistory: Message[],
  currentTopic: Topic,
  prompt: string
): Array<{ role: string; content: string }> => {
  const systemMessageContent =
    currentTopic.mode === 'roleplay' && currentTopic.roleplayInfo
      ? currentTopic.roleplayInfo.systemMessage
      : currentTopic.freetalkInfo?.systemMessage || '';

  return [
    { role: 'system', content: systemMessageContent },
    ...messagesHistory.map((message) => ({
      role: message.user === 'user' ? 'user' : 'assistant',
      content: message.text,
    })),
    { role: 'user', content: prompt },
  ];
};
