// ChatInput.tsx
import React, { useState, useCallback } from 'react';
import AudioRecorder from './AudioRecorder';
import { transcribeAudio } from '../../../utils/transcribeAudio';
import styles from './ChatInput.module.css'; // Import the styles

type Props = {
  onSubmit: (message: string) => void;
  onTranscriptionComplete: (transcribedText: string) => void;
  onEvaluate: () => void; // 追加
};

const ChatInput: React.FC<Props> = ({
  onSubmit,
  onEvaluate, // 追加
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleRecordingComplete = async (audioFile: File) => {
    const transcribedText = await transcribeAudio(audioFile);
    setInputValue(transcribedText);
  };

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    if (inputValue.trim()) {
      onSubmit(inputValue);
      setInputValue('');
    }
  }, [inputValue, onSubmit]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className={`input-group position-sticky bottom-0 ${styles.formBackground}`}
    >
      <textarea
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        className={`form-control me-2 ${styles.textareaFormControl}`}
        placeholder="Type your message"
      />
      <div className={`d-flex`}>
        <AudioRecorder onRecordingComplete={handleRecordingComplete} />
        <button
          type="button"
          className="btn btn-secondary ms-2"
          style={{ backgroundColor: "var(--secondary)" }}
          onClick={onEvaluate}
        >
          評価
        </button>
        <button type="submit" className="btn btn-primary ms-2">
          送信
        </button>
      </div>

    </form>
  );
};

export default ChatInput;