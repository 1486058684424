// ChatArea.tsx:
import React, { useRef, useEffect, useState, useCallback } from 'react';
import ChatMessages from './ChatMessages';
import ChatInput from '../ChatInput/ChatInput';
import styles from './ChatArea.module.css';
import { useChat } from '../../../contexts/ChatContext';
import { evaluateConversation } from '../../../utils/evaluateConversation';

const ChatArea: React.FC = () => {
    const { activeTopic, sendChatMessage } = useChat();
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const chatMessagesAreaRef = useRef<HTMLDivElement>(null);

    const [evaluationMessage, setEvaluationMessage] = useState<string | null>(null);

    const updateScrollPosition = useCallback(() => {
        if (chatMessagesAreaRef.current) {
            chatMessagesAreaRef.current.scrollTop = chatMessagesAreaRef.current.scrollHeight;
        }
    }, []);

    const handleEvaluateClick = async () => {
        if (activeTopic) {
            const evaluationResult = await evaluateConversation(activeTopic.messages);
            setEvaluationMessage(evaluationResult);
        }
    };

    useEffect(() => {
        console.log("ChatArea.tsx");
        console.log(activeTopic);
    }, [activeTopic?.messages, activeTopic]);

    useEffect(() => {
        updateScrollPosition();
    }, [activeTopic?.messages, activeTopic?.id, updateScrollPosition]);
    return (
        <div ref={chatContainerRef} className={`d-flex flex-column ${styles.chatContainer}`}>
            <h2 className="mb-3 fs-1 fw-bold">{activeTopic?.title}</h2>
            <div ref={chatMessagesAreaRef} className={styles.chatMessagesArea}>
                {activeTopic && <ChatMessages messages={activeTopic.messages} />} {/* この行を変更 */}
            </div>
            {evaluationMessage && (
                <div
                    className="bg-success text-white rounded p-2 mb-2 w-100 overflow-auto"
                    style={{ minHeight: '100px', maxHeight: '300px', width: '95%' }}
                >
                    <p style={{ whiteSpace: 'pre-wrap' }}>{evaluationMessage}</p>
                </div>
            )}
            <div className={styles.chatInputArea}>
                <ChatInput
                    onSubmit={sendChatMessage}
                    onTranscriptionComplete={sendChatMessage}
                    onEvaluate={handleEvaluateClick}
                />
            </div>
        </div>
    );
};

export default ChatArea;