// evaluateConversation.ts
import axios from 'axios';
import { Message } from '../types';

const OPENAI_API_BASE_URL = 'https://api.openai.com/v1';

export const evaluateConversation = async (
  messagesHistory: Message[],
): Promise<string> => {

  const conversationData = messagesHistory
    .map((message) => `${message.user === 'user' ? 'ユーザー' : 'AI'}: 「${message.text}」`)
    .join('\n');

  const evaluationSystemMessage = {
    role: 'system',
    content: `以下はAIとユーザーの会話です。ユーザーは日本語の初心者です。
        ユーザーの会話データを評価し、簡単でわかりやすいフィードバックを提供してください。      
      
      評価項目:
      - 文法の誤り
      - 不自然な日本語の表現
      
      会話データ:
      ${conversationData}
      
      指示:
      - 評価結果を以下の形式で出力してください。\n文法の誤り: 「指摘箇所」 -> 修正案\n不自然な日本語表現: 「指摘箇所」 -> 修正案
      - 指摘箇所を「」で囲んで具体的に指摘し、修正案を提示してください。
      - 不自然な表現について、明確ではない場合は「不明確」と指摘してください。
      - 出力時に各評価結果を改行して表示してください。\n
      - 259文字以内で出力してください。
      `
      
  };

  console.log('Input:', evaluationSystemMessage);

  const response = await axios.post(
    `${OPENAI_API_BASE_URL}/chat/completions`,
    {
      model: 'gpt-4-1106-preview',
      messages: [evaluationSystemMessage],
      max_tokens: 250,
      n: 1,
      stop: null,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
    }
  );

  const result = response.data.choices[0].message.content.trim();
  console.log('Output:', result); // 出力値のログを出力

  return result;
};
