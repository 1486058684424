// ChatMessages.tsx
import React, { useCallback } from 'react';
import { Message } from '../../../types';
import styles from './ChatMessages.module.css';

type Props = {
  messages: Message[];
};

const ChatMessages: React.FC<Props> = ({ messages }) => {
  const playAudio = useCallback((audio: ArrayBuffer) => {
    const audioElement = new Audio(URL.createObjectURL(new Blob([audio], { type: "audio/mpeg" })));
    audioElement.play();
  }, []);

  return (
    <div className="rounded p-3 mb-2 flex-grow-1 h-100">
      {messages.map((message) => (
        <div
          key={message.id}
          className={`d-flex ${message.user === 'assistant' ? 'justify-content-start' : 'justify-content-end'} mb-4`}
        >
          <div
            className={`bg-${message.user === 'assistant' ? 'primary' : 'secondary'} text-white rounded p-2 ${styles.message}`}
          >
            {message.text}
            {message.audio && (
              <button
                onClick={() => message.audio && playAudio(message.audio)}
                className="btn btn-sm btn-outline-light ml-2"
              >
                <i className="fas fa-play fa-xs"></i>
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessages;
