// fetchGPTResponse.ts
import axios from 'axios';
import { Message, Topic } from '../types';
import { transformMessages } from './transformMessages';

const OPENAI_API_BASE_URL = 'https://api.openai.com/v1';

export const fetchGPTResponse = async (
  prompt: string,
  messagesHistory: Message[],
  currentTopic: Topic,
  model: string = 'gpt-4-1106-preview'
): Promise<string> => {
  try {
    const transformedMessages = transformMessages(messagesHistory, currentTopic, prompt);
    console.log(transformedMessages);

    const response = await axios.post(
      `${OPENAI_API_BASE_URL}/chat/completions`,
      {
        model,
        messages: transformedMessages,
        max_tokens: 250,
        n: 1,
        stop: null,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      }
    );

    console.log(response.data.choices[0].message.content.trim());

    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error fetching GPT response:', error);
    throw error;
  }
};
