// topicsData.ts
import { Topic, Message, FreetalkInfo, RoleplayInfo } from '../types';
import { parse } from 'yaml';

export let defaultInitialMessage: Message = { id: 0, text: '', user: 'assistant' }; 
export let defaultSystemMessage: string = '';

interface TopicData {
  id: number;
  title: string;
  mode: 'freetalk' | 'roleplay';
  initialMessage: Message;
  freetalkInfo?: FreetalkInfo;
  roleplayInfo?: RoleplayInfo;
}

interface FreetalkTopicData {
  id: number;
  title: string;
  mode: 'freetalk';
  initialMessage: Message;
  freetalkInfo: FreetalkInfo;
}

interface RoleplayTopicData {
  id: number;
  title: string;
  mode: 'roleplay';
  initialMessage: Message;
  roleplayInfo: RoleplayInfo;
}

interface TopicsData {
  freetalkTopics: FreetalkTopicData[];
  roleplayTopics: RoleplayTopicData[];
}

async function loadTopicsData(): Promise<TopicsData> {
  try {
    const response = await fetch('topicsData.yaml');
    const yamlData = await response.text();
    const data = parse(yamlData);
    return data.topics;
  } catch (error) {
    console.error('Error reading topics data from file:', error);
    throw error;
  }
}

export const getTopicsData = async (): Promise<{ freetalkTopics: Topic[]; roleplayTopics: Topic[] }> => {
  const topics: TopicsData = await loadTopicsData();

  const defaultFreetalkTopic = topics.freetalkTopics.find((topic) => topic.id === 1);
  if (defaultFreetalkTopic) {
    defaultInitialMessage = defaultFreetalkTopic.initialMessage;
    defaultSystemMessage = defaultFreetalkTopic.freetalkInfo?.systemMessage || '';
  }

  const freetalkTopics: Topic[] = topics.freetalkTopics.map((topic) => ({
    id: topic.id,
    title: topic.title,
    messages: [topic.initialMessage],
    mode: 'freetalk',
    freetalkInfo: {
      systemMessage: topic.freetalkInfo?.systemMessage || '',
      language: topic.freetalkInfo?.language || 'ja',
    },
  }));

  const roleplayTopics: Topic[] = topics.roleplayTopics.map((topic) => ({
    id: topic.id,
    title: topic.title,
    messages: [topic.initialMessage],
    mode: 'roleplay',
    roleplayInfo: {
      scenario: topic.roleplayInfo?.scenario || '',
      systemMessage: topic.roleplayInfo?.systemMessage || '',
      language: topic.roleplayInfo?.language || 'ja',
      userRole: topic.roleplayInfo?.userRole || 'Leader',
    },
  }));

  return {
    freetalkTopics,
    roleplayTopics,
  };
};
